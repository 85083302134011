import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const ReasonsTryOil = () => (
  <Layout>
    <SEO
      title="5 Reasons to Try Oil on Natural Hair"
      description="Oils have a very specific use in hair care and you definitely shouldn’t think of them as just another type of conditioner"
    />
    <h1>5 Reasons to Try Oil on Natural Hair</h1>
    <p>A lot of us use leave-in conditioners and other types of hair products but so many of you are missing out on the one thing that could make all the difference to the health of your hair. Oils have a very specific use in hair care and you definitely shouldn’t think of them as just another type of conditioner. They are far from that and if you don’t already use oils, I’m sure you will after you read what’s ahead.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/9u7w9H6.jpg"
        alt="natural hair oil"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photo credit: <a
        href="https://unsplash.com/@crystalweed"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >CRYSTALWEED cannabis</a></p>
    </div>

    <p>If you suffer with moisture control and conditioning just doesn’t do what you want it to do then using an oil at the right stage in your routine can help a lot. Hydration/water can evaporate during the day and no amount of conditioner will stop that from happening. It’s a lot more exaggerated and noticeable in extreme temperatures and harsh direct sunlight.</p>
    <p>Here are the benefits of oils that help give you beautiful hair</p>

    <h2>1. Moisture control</h2>
    <p>By far, the number one benefit of using oil is moisture control. When you wash your hair and you have dried it (Remember to towel try leaving your hair slightly damp, definitely don’t hot blow dry), the first thing you should do is massage oil into your hair.</p>
    <p>The oil seals the water into your hair strands and keeps your hair hydrated. This works because water and oil do not mix so the oil acts like a barrier, stopping the water from escaping. It does a far better job than a leave-in conditioner would. The leave in conditioner also helps but with the added benefit of oil, your hair should stay hydrated for so much longer.</p>

    <h2>2. Added Shine</h2>
    <p>We all know the properties of oil, whether in the hair or not. The desirable one for use is the added shine it gives to your hair once it’s applied. You can add generously depending on your hair type so if you aren’t sure then add it bit by bit while you feel and look in the mirror to see how you are getting on. It’s very hard to add too much but if you manage to do that then wipe your hair down with soft flannel then wash that flannel with soapy water later on.</p>

    <h2>3. Wonderful Smell</h2>
    <p>Each oil has a different smell and you can even get the same one with a stronger or lighter smell. You can go with nearly no fragrance at all which will be unnoticeable when you’re done because the smell of the conditioner or any other products you use will take over.</p>
    <p>There are so many oils to choose from which means you are sure to find at least one that you find pleasant. If not, just use one for its other properties.</p>

    <h2>4. Extra Protection</h2>
    <p>If you apply any kind of heat to your hair, adding oil to your hair before you do can prevent a whole lot of pain and damage later on. Heat can temporarily change the structure of the hair. Prolonged heat increases the probability of permanent damage. A good oil and preheat routine can help reduce the chances of that permanent damage so you get the benefits of any heat you might want to apply and miss out on the negatives. IN other words, you get to keep your curls and kinks.</p>

    <h2>5. Scalp Care</h2>
    <p>If you suffer with a dry scalp or dandruff and you’ve tried other things with no success, it can’t hurt to give oil a try. Oil works for a lot of people because it is not as harsh as conditioners, it doesn’t contain anywhere near the number of ingredients (often just one) and it is nourishing. Depending on your skin type, oils are also far less of an irritant so you can combat your dry scalp without having to itch your head all day.</p>


    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}



      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>  
        <p>
            <Link to="/14-tips-on-how-to-grow-afro-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            14 tips on how to grow afro hair
            </Link>{" "}
            - Growing afro hair can be one of the most annoying things in the world, the good news is that growing natural doesn't have to be hard. Read our 14 tips to make your life easier here...
          </p>             
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default ReasonsTryOil
